import { Image } from '@atoms/index';
import { useRouter } from 'next/router';
import Logo from '../../public/images/jiraaf.svg';
import MainTenancePageImage from '../../public/images/construction.svg';
import RetryIcon from '../../public/images/redo.svg';
import { SUPPORT_EMAIL } from '../../lib/helpers';

const FallbackUI = ({ statusCode }) => {
    const router = useRouter();

    return (
        <div className="relative z-50 w-full min-h-screen bg-white">
            <div className="flex flex-col items-center justify-between min-h-screen gap-5 px-5 py-10 bg-basicWhite">
                {/* Logo */}
                <div className="flex-1">
                    <Image
                        className="h-8 cursor-pointer xl:w-full w-[90px]"
                        onClick={() => router.push('/')}
                        src={Logo.src}
                        alt="logo"
                    />
                </div>

                <div className="flex-1 text-center">
                    <h2 className="px-6 mb-4 text-xl font-semibold md:tracking-tight font-bahnschrift md:text-32px h4 md:h2 text-primary-500">
                        {statusCode === 404
                            ? 'You seem to have landed on a wrong page'
                            : 'Sorry, our website has encountered an issue'}
                    </h2>

                    <h6 className="mb-10 text-sm font-normal text-gray-700 font-montserrat md:text-base">
                        {statusCode === 404
                            ? 'Please check the URL or click on the button below to return to the home page.'
                            : 'We will be back up shortly. Meanwhile, please click on the button below to retry.'}
                    </h6>
                    <div className="flex justify-center mb-12 align-center">
                        <Image
                            src={MainTenancePageImage.src}
                            alt="maintenance image"
                            className="max-w-[500px] w-full"
                        />
                    </div>

                    <div className="flex flex-col items-center justify-center mb-8 xl:mb-16">
                        <button
                            onClick={() => {
                                if (statusCode === 404) {
                                    router.push('/');
                                } else {
                                    router.reload();
                                }
                            }}
                            className="flex items-center justify-center px-6 py-3 text-sm font-semibold text-white h5 md:text-base bg-primary-500 rounded-xl"
                        >
                            {statusCode !== 404 ? (
                                <Image
                                    className="mr-2"
                                    src={RetryIcon.src}
                                    alt="retry icon"
                                />
                            ) : null}
                            {statusCode === 404 ? 'Go to home page' : 'Retry'}
                        </button>
                    </div>

                    <div className="flex flex-col items-center justify-center bottom-content">
                        <p className="text-sm font-medium h5 md:text-lg text-basicBlack font-montserrat">
                            Need help? Write to us on{' '}
                            <span
                                className="cursor-pointer text-secondary-600"
                                onClick={(e) => {
                                    window.location = `mailto:${SUPPORT_EMAIL}'`;
                                    e.preventDefault();
                                }}
                            >
                                {SUPPORT_EMAIL}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FallbackUI;